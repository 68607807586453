<template>
  <div>
    <Pane />
    <a-card class="container">
      <a-form
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
        :colon="false"
        :form="form"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="预算编号">
              {{ user?.code }}
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="填报单位">
              {{ user?.deptName }}
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="填报人">
              {{ user?.creatorName }}
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="填报时间">
              {{ user?.createAt }}
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="费用项清单"
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 19 }"
            >
              <Subject :list="list" />
            </a-form-item>
          </a-col>

        </a-row>

        <div class="center">
          <a-space>
            <a-button @click="$close($route.path)">关闭</a-button>
            <a-button type="primary" :loading="loading" @click="process"
              >保存并发起</a-button
            >
            <a-button type="primary" :loading="loading" @click="handleSubmit"
              >保存</a-button
            >
          </a-space>
        </div>
      </a-form>
    </a-card>
  </div>
</template>

<script>
import request from "@/api/request";
import Subject from "./components/subject.vue";
import organization from "@/mixins/organization";
function getDetail(id) {
  return request({
    url: "/office-service/quality/fee/budget/detail/" + id,
  });
}
function save(data) {
  return request({
    url: "/office-service/quality/fee/budget/modify",
    method: "post",
    data
  });
}
export default {
  name: "technicalQualityScoreAdd",
  mixins: [organization],
  components: {
    Subject,
  },

  data() {
    return {
      code: '',
      form: this.$form.createForm(this),
      user: '',
      list: [],
      users: [],
      judges: [],

      loading: false,
    };
  },
  mounted() {
    const { query } = this.$route;
    const { id } = query || {};
    getDetail(id).then(res => {
      this.user = res;
      this.list = res.itemList;
    });
  },
  methods: {
    hanldeSave(isRaise) {
      if (!this.list.length) {
        this.$message.error("请填写费用项清单");
        return;
      }

      this.loading = true;
      save({
        id: this.user.id,
        code: this.user.code,
        creatorId: this.user.creatorId,
        creatorName: this.user.creatorName,
        deptId: this.user.deptId,
        deptName: this.user.deptName,
        isRaise: isRaise,
        itemList: this.list
      })
      .then(() => {
        this.$close(this.$route.path);
      })
      .finally(() => {
        this.loading = false;
      });
    },
    process(e) {
      e.preventDefault();
      this.hanldeSave(true);
    },
    handleSubmit(e) {
      e.preventDefault();
      this.hanldeSave(false);
    }
  },
};
</script>


<style lang="less" scoped>
.container {
  padding: 12px;
}

.center {
  margin-top: 80px;
  margin-bottom: 80px;
}
</style>