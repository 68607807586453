<template>
  <div>
    <a-button
      v-if="showBtn"
      style="float: right;margin-bottom: 10px;z-index: 99999;"
      @click="viewHelper"
      :disabled="disabled"
      >填报助手</a-button
    >
    <a-button
      v-if="showBtn"
      style="float: right;margin-right: 10px;margin-bottom: 10px;z-index: 99999;"
      type="primary"
      @click="visible = true"
      :disabled="disabled"
      >新增</a-button
    >
    <a-table bordered :data-source="[...list, listAll]" :pagination="false">
      <a-table-column title="序号" width="50px" align="center">
          <template slot-scope="text, row, index">
              {{ index === list.length ? '合计' : index + 1 }}
          </template>
      </a-table-column>
      <a-table-column title="费用名称" data-index="feeName"></a-table-column>
      <a-table-column title="项目" data-index="itemName"></a-table-column>
      <a-table-column title="预计投入(元)" data-index="amount"></a-table-column>
      <a-table-column title="备注" data-index="remark"></a-table-column>
      <a-table-column v-if="showBtn" title="操作" align="center" width="100px">
        <template slot-scope="text, record, index">
          <a
            v-if="index < list.length"
            href="#"
            :class="disabled ? '' : 'danger'"
            @click.prevent="remove(index)"
            :disabled="disabled"
            >删除</a
          >
        </template>
      </a-table-column>
    </a-table>

    <a-modal
      :visible="visible"
      title="费用预算项添加"
      :footer="null"
      @cancel="cancel"
    >
      <a-form :form="form" :colon="false" @submit="handleSubmit" width="640px">
        <a-form-item label="费用名称">
          <a-select
            @change="setItemName"
            v-decorator="[
                'feeName',
                {
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]"
            >
            <a-select-option
              v-for="item in feeNameList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</a-select-option>
            </a-select>
        </a-form-item>

        <a-form-item label="项目">
          <a-select
            v-decorator="[
                'itemName',
                {
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]"
            >
            <a-select-option
              v-for="item in itemNameList"
              :key="item.id"
              :value="item.name"
              >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="预计金额(元)">
          <a-input-number
            :min="0"
            v-decorator="[
              'amount',
              {
                rules: [{ required: true, message: '请输入！' }],
              },
            ]"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item label="备注">
          <a-input
            v-decorator="[
              'remark',
              {
                rules: [{ message: '请输入！' }],
              },
            ]"
          />
        </a-form-item>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit">提交</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>

  </div>
</template>

<script>
import request from "@/api/request";
function getFeeName(data) {
  return request({
    url: "/office-service/quality/constant/queryListByPid",
    data,
    method: "POST"
  });
}
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showBtn: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
      visible: false,
      feeNameList: [],
      itemNameList: []
    };
  },

  computed: {
    listAll() {
      let amount = 0;
      this.list.forEach(item => {
          if (typeof item.amount === 'number') {
            amount += item.amount;
          }
      });
      return {
        amount,
      }
    },
  },

  mounted() {
    getFeeName({
      type: "fee",
      pid: null
    }).then(res => {
      this.feeNameList = res;
    });
  }, 

  methods: {
    setItemName(id){
      getFeeName({
        type: "fee",
        pid: id
      }).then(res => {
        this.itemNameList = res;
      });
    },
    viewHelper() {
      window.open("https://qiniu.njszy.com/linkcloud/production/helper.pdf");
    },
    cancel() {
      this.visible = false;
      this.form.resetFields();
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.list.push({
            ...values,
            feeName: this.feeNameList.filter(v=>v.id == values.feeName)[0].name
          });
          this.cancel();
        }
      });
    },

    remove(index) {
      this.list.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.tips {
  color: #999;
  span {
    padding-left: 0.5em;
  }
}
</style>